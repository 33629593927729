import React from 'react'
import MemphisPattern from '@components/MemphisPattern'
import HeroBoxed from '@widgets/HeroBoxed'
import { Heading, Button } from 'theme-ui'
import { Link } from 'gatsby'
import Box from './Faq.Box'



const Faq = () => (
  <>
  <HeroBoxed.Wrapper>


     <div className="boxsezione9"> 
     <div style={{width:"100%", textAlign:"center", color:"#718096"}}>
 
</div>
<div>
<Heading variant='h3'style={{textAlign:"center"}}>
Hai qualche dubbio?<br></br><br></br>
Abbiamo raccolto alcune delle domande che ci sono state poste più frequentemente
<br></br>
     </Heading>
     
<h4>FAQ</h4><br></br>   

<Box domanda=" ✈︎ Perché dovrei rivolgermi a EURORIMBORSO e non proseguire autonomamente?" risposta="Eurorimborso è uno dei più importanti portali italiani online che ti assiste gratuitamente nella richiesta di risarcimento per disagi aerei. "></Box>
<Box domanda=" ✈︎ Cosa fare per ricevere l’assistenza di EURORIMBORSO?" risposta="È sufficiente inserire i dati richiesti nel Form di richiesta di risarcimento disponibile sul portale. Entro 24 ore verrai contattato da un consulente di Eurorimborso che ti comunicherà se hai diritto alla compensazione economica e al risarcimento del danno previsti dalla normativa comunitaria e nazionale. Successivamente, Eurorimborso avvierà le procedure per il riconoscimento dei diritti del passeggero sia in sede stragiudiziale (cercando di raggiungere un accordo bonario con la Compagnia Aerea) che in sede giudiziale (ovvero rivolgendoci al Giudice di Pace o Tribunale, se la Compagnia Aerea non è stata disponibile a concludere un accordo bonario). "></Box>
<Box domanda=" ✈︎ Cosa succede nel caso in cui il passeggero non abbia diritto al risarcimento?" risposta="Eurorimborso non addebiterà nulla al passeggero anche se la compagnia aerea non risulterà responsabile del disservizio arrecato. "></Box>
<Box domanda=" ✈︎ Quali sono i rischi?" risposta="Il passeggero, con Eurorimborso, non assume alcun rischio e deve solo attendere comodamente da casa di ricevere il risarcimento dovuto."></Box>


<br></br> <h4></h4><br></br>   







    </div>
    </div>
  </HeroBoxed.Wrapper>
  
  </>
)



export default Faq